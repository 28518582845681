import React, { Component } from "react"
import ReactMarkdown from 'react-markdown'
import Player from 'react-player/lib/players/YouTube'
import './VideoPlayer.scss'

function buildUrl (videoId) {
  return `https://www.youtube.com/watch?v=${videoId}`
}

class VideoPlayer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      url: buildUrl(this.props.videoInfo.videoId)
    }

    this.splitDescription = this.splitDescription.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.videoInfo.videoId !== nextProps.videoInfo.videoId) {
      this.setState({ url: buildUrl(nextProps.videoInfo.videoId) })
    }
  }

  splitDescription () {
    // By Markdown standards, a hard line-break can be accomplished by ending a line with
    // two spaces before the \n. Here we format the string appropriately for rendering.
    const desc = this.props.videoInfo.description
    return desc ? desc.replace('\n', '  \n') : ''
  }

  render () {
    const playerVars = { modestbranding: 1 }
    return (
      <div className="video-player">
        <div className="video-player-container">
          <div className="video-player-wrapper">
            <Player
              className='react-player'
              url={this.state.url}
              width='100%'
              height='100%'
              config={{ youtube: { playerVars } }}
              controls
            />
          </div>
        </div>
        <div className="info-container">
          <h2>{this.props.videoInfo.title}</h2>
          <ReactMarkdown source={this.splitDescription()} />
          <div className="video-meta"><b className="yellow">Made by:</b> {this.props.videoInfo.madeBy}</div>
          <div className="video-meta"><b className="orange">Starring:</b> {this.props.videoInfo.starring}</div>
          <div className="video-meta"><b className="teal">Edited by:</b> {this.props.videoInfo.editedBy}</div>
        </div>
      </div>
    )
  }
}

export default VideoPlayer
