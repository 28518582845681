import React, { Component } from "react"
import './VideoThumbnail.scss'

class VideoThumbnail extends Component {
  constructor (props) {
    super(props)
    this.videoInfo = props.videoInfo
    this.switchVideo = () => props.switchVideo(this.videoInfo.videoId)
  }

  render() {
    return (
      <div className="video-thumbnail" onClick={this.switchVideo}>
        <div className="image-wrapper">
          <img className="thumbnail" alt="" src={`https://img.youtube.com/vi/${this.videoInfo.videoId}/hqdefault.jpg`} />
        </div>
        <div className="title">{this.videoInfo.title}</div>
      </div>
    )
  }
}

export default VideoThumbnail
