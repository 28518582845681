import React, { Component } from 'react'
import './VideoGrid.scss'

class VideoGrid extends Component {
  render() {
    const { children } = this.props
    return (
      <div className="video-grid">
        {children.length > 0 ? children : <div>There is nothing here. :(</div>}
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
        <div className="placeholder" />
      </div>
    )
  }
}

export default VideoGrid
