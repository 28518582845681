import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { compact, filter, find, map } from 'lodash'
import $ from 'jquery'
import Helmet from "react-helmet"
import Layout from "../layout"
import config from "../../data/SiteConfig"
import './collection.scss'

import SideBar from '../components/SideBar/SideBar'
import VideoPlayer from '../components/VideoPlayer/VideoPlayer'
import VideoGrid from '../components/VideoGrid/VideoGrid'
import VideoThumbnail from '../components/VideoThumbnail/VideoThumbnail'

function renderVideos (videos, switchVideo, category) {
  let videoSubset = videos
  if (category) {
    videoSubset = filter(videos, { category })
  }
  return videoSubset.map(v => <VideoThumbnail key={v.videoId} videoInfo={v} switchVideo={switchVideo} />)
}

class CollectionPage extends Component {
  constructor (props) {
    super(props)
    this.switchVideo = this.switchVideo.bind(this)

    const allVideos = compact(map(props.data.allGoogleSpreadsheetMetadata.edges, e => e.node))
    let videoInfoToSet = filter(allVideos, { category: 'Official' })[0]
    if (this.props.location.state && this.props.location.state.videoId) {
      videoInfoToSet = find(allVideos, v => v.videoId === this.props.location.state.videoId)
    }

    this.state = {
      allVideos,
      videoInfo: videoInfoToSet,
    }
  }

  switchVideo (videoId) {
    this.setState({
      videoInfo: find(this.state.allVideos, v => v.videoId === videoId)
    })
    $("html, body").animate({ scrollTop: 0 }, 'fast')
  }

  render () {
    return (
      <Layout location={this.props.location}>
        <div className="collection-container">
          <Helmet title={`Collection | ${config.siteTitle}`} />
          <div className="header">
            The Collection
            <SideBar />
          </div>
          <VideoPlayer videoInfo={this.state.videoInfo} />

          <Tabs className="tabs-container">
            <TabList>
              <Tab className={['react-tabs__tab', 'bg-mint']}>
                <span className="long-name">Avstract Official Films</span>
                <span className="short-name">Official</span>
              </Tab>
              <Tab className={['react-tabs__tab', 'bg-yellow']}>
                <span className="long-name">Peanuts</span>
                <span className="short-name">Peanuts</span>
              </Tab>
              <Tab className={['react-tabs__tab', 'bg-orange']}>
                <span className="long-name">BTS & Featurettes</span>
                <span className="short-name">BTS</span>
              </Tab>
              <Tab className={['react-tabs__tab', 'bg-red']}>
                <span className="long-name">The Vault</span>
                <span className="short-name">Vault</span>
              </Tab>
              <Tab className={['react-tabs__tab', 'bg-teal']}>
                <span className="long-name">All Videos</span>
                <span className="short-name">All</span>
              </Tab>
            </TabList>

            <TabPanel className={['react-tabs__tab-panel', 'bg-mint']}>
              <VideoGrid>
                {renderVideos(this.state.allVideos, this.switchVideo, 'Official')}
              </VideoGrid>
            </TabPanel>
            <TabPanel className={['react-tabs__tab-panel', 'bg-yellow']}>
              <VideoGrid>
                {renderVideos(this.state.allVideos, this.switchVideo, 'Peanuts')}
              </VideoGrid>
            </TabPanel>
            <TabPanel className={['react-tabs__tab-panel', 'bg-orange']}>
              <VideoGrid>
                {renderVideos(this.state.allVideos, this.switchVideo, 'BTS')}
              </VideoGrid>
            </TabPanel>
            <TabPanel className={['react-tabs__tab-panel', 'bg-red']}>
              <VideoGrid>
                {renderVideos(this.state.allVideos, this.switchVideo, 'Vault')}
              </VideoGrid>
            </TabPanel>
            <TabPanel className={['react-tabs__tab-panel', 'bg-teal']}>
              <VideoGrid>
                {renderVideos(this.state.allVideos, this.switchVideo)}
              </VideoGrid>
            </TabPanel>
          </Tabs>
        </div>
      </Layout>
    )
  }
}

export default CollectionPage

/* eslint no-undef: "off" */
export const query = graphql`
  query Query {
    allGoogleSpreadsheetMetadata {
      edges {
        node {
          videoId
          category
          title
          description
          madeBy
          starring
          editedBy
        }
      }
    }
  }
`
